import React from "react";
import { Link } from "gatsby";

export default class Schedule extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;

    const calendlyEmbed = document.querySelector(".calendly-embed");
    calendlyEmbed.appendChild(script);

    setTimeout(function() {
      if (document.querySelector("iframe")) {
        document.querySelector("iframe").style.height = "1000px";
      }
    }, 3000);
  }

  render() {
    return (
      <React.Fragment>
        <h1 className="title is-size-1 has-text-black has-text-weight-bold">
          Schedule An Appointment
        </h1>
        <p className="has-text-grey">
          We strongly encourage all clients on our Cognitive Health Program to
          make an Appointment in order to heighten their experience and
          customize their protocol. Our personalized services include our
          Private Dietitian Appointment ($350), Lab Analysis and Supplement
          Guidance ($450), and our Personalized 1-Week Meal Plan ($475). To book
          your private appointment, select an available date and time on the
          calendar below. We will then email you an online invoice and a link
          that will connect you at the time of your appointment. For more
          information about rescheduling an appointment you have already made or
          cancelling an appointment, please visit our{" "}
          <Link to="/account/help/">
            <strong>Help Center </strong>
          </Link>
          or send us an email at info@amosinstitute.com
        </p>
        <div className="calendly-embed">
          <div
            style={{ minWidth: "320px", height: "1000px" }}
            className="calendly-inline-widget"
            data-url="https://calendly.com/amosinstitute/50min?hide_event_type_details=1"
          ></div>
        </div>
      </React.Fragment>
    );
  }
}
