import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import "../../components/all.sass";
import { Router } from "@reach/router";
import { Link } from "gatsby";
import { login, isAuthenticated, getProfile, tokens } from "../../utils/auth";
import {
  faunaReadProgramInfo,
  fuanaCreateNewProgramInfo,
} from "../../utils/fauna";
import moment from "moment";
import Spinner from "../../components/Spinner";
import ErrorMsg from "../../components/account/ErrorMsg";

import Dashboard from "../../components/Dashboard";
import Lesson from "../../components/Lesson";
import Schedule from "../../components/Schedule";
import Help from "../../components/Help";
import Assessments from "../../components/account/Assessments";
// import LessonDev from "../../components/account/Lesson";

import dashboardIcon from "../../img/icons/home-outline.svg";
import appointmentIcon from "../../img/icons/calendar-outline.svg";
import communityIcon from "../../img/icons/message-circle-outline.svg";
import infoIcon from "../../img/icons/question-mark-circle-outline.svg";
import lessonIcon from "../../img/icons/play-circle-outline.svg";

// This page dynamically renders the account and it's subpages
// via @reach/router

export default class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      accountObj: {},
      programInfo: {},
      isLoading: true,
      error: { status: false, msg: "" },
      accountPermission: false,
      isEntryState: false,
      isExitState: false,
    };

    this.setAccountStateFromChild = this.setAccountStateFromChild.bind(this);
  }

  setAccountStateFromChild(value) {
    console.log("Updated state from child.");
    this.setState(value);
  }

  componentDidMount() {
    window.addEventListener(
      "new_session",
      function() {
        this.setState({ isLoading: false });
      }.bind(this)
    );

    if (!isAuthenticated()) {
      login();
    }

    getProfile()
      .then(
        function(profile) {
          console.log("PROFILE", profile);
          this.setState({ user: profile });
        }.bind(this)
      )
      .then(
        function() {
          const user = this.state.user;

          faunaReadProgramInfo(tokens.accessToken, user.email)
            .then(
              function(data) {
                console.log(data);

                if (!data) {
                  console.log("No Fuana Profile Data Found.");

                  fuanaCreateNewProgramInfo(
                    tokens.accessToken,
                    user.email
                  ).then(
                    function(data) {
                      console.log(data);
                      this.setState({ programInfo: data });
                      this.setupAccountObject(user, data);
                    }.bind(this)
                  );
                } else {
                  this.setState({ programInfo: data });
                  this.setupAccountObject(user, data);
                }
              }.bind(this)
            )
            .catch(
              function(err) {
                this.setState({
                  error: {
                    status: true,
                    msg: "Error setting account properties. " + err,
                  },
                });
              }.bind(this)
            );
        }.bind(this)
      );
  }

  setupAccountObject(user, data) {
    if (!data) {
      return;
    }

    let ACCOUNT_OBJ = {
      email: user.email,
      appMetadata: user["https://amosinstitute.com/app_metadata"],
      userMetadata: user["https://amosinstitute.com/user_metadata"],
      sub: user.sub,
    };

    if (ACCOUNT_OBJ.appMetadata) {
      ACCOUNT_OBJ.startDate = moment(
        ACCOUNT_OBJ.appMetadata.CHPStartDate,
        "YYYY-MM-DD"
      ).format("MM/DD/YYYY");

      ACCOUNT_OBJ.currentDate = moment().format("l");
      ACCOUNT_OBJ.currentWeek = moment(ACCOUNT_OBJ.currentDate).diff(
        moment(ACCOUNT_OBJ.startDate),
        "weeks"
      );

      if (
        ACCOUNT_OBJ.appMetadata &&
        !ACCOUNT_OBJ.appMetadata.isCHPSuspended &&
        ACCOUNT_OBJ.appMetadata.hasCHPAccess
      ) {
        this.setState({ accountPermission: true });
      } else {
        this.setState({ isError: true });
      }

      this.setState({
        isExitState:
          !data.offboarding.isComplete &&
          ACCOUNT_OBJ.currentWeek > 25 &&
          ACCOUNT_OBJ.userMetadata.entryComplete,
      });

      this.setState({
        isEntryState:
          !data.offboarding.isComplete && !data.onboarding.isComplete,
      });

      console.log("this.state.isEntryState", this.state.isEntryState);
      console.log("this.state.isExitState", this.state.isExitState);
    } else {
      this.setState({
        error: {
          status: true,
          msg:
            "There was an issue determining account status. Please email our team at info@amosinstitute.com",
        },
      });
    }

    console.log("ACCOUNT_OBJ", ACCOUNT_OBJ);
    this.setState({ accountObj: ACCOUNT_OBJ });
    this.setState({ isLoading: false });
  }

  render() {
    let ACCOUNT_OBJ = this.state.accountObj;

    if (this.state.error.status) {
      return <ErrorMsg msg={this.state.error.msg} />;
    }

    if (
      this.state.isLoading &&
      (Object.keys(this.state.user).length === 0 ||
        Object.keys(this.state.accountObj).length === 0)
    ) {
      return <Spinner />;
    }

    if (!this.state.accountPermission) {
      return (
        <ErrorMsg
          title="You must have a valid account to access this page."
          msg="If you believe you should have access, please
      send us an email at info@amosinstitute.com"
        />
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>My Account | Amos Institute</title>
          <meta name="robots" content="noindex" />
          <meta name="referrer" content="origin" />
          <link
            rel="icon"
            type="image/png"
            href="https://amosinstitute.com/img/favicon-32x32.png"
            sizes="32x32"
          />
        </Helmet>
        <Navbar />
        <div className="container">
          <div className="columns">
            <div className="column">
              <nav
                className={`account-nav menu ${
                  this.state.isEntryState &&
                  window.sessionStorage.getItem("isOnboardingComplete") !==
                    "true"
                    ? "hidden"
                    : null
                }`}
              >
                <ul className="menu-list has-text-centered">
                  <li>
                    <Link
                      to="/account/"
                      className={
                        this.props.location.pathname === "/account/"
                          ? "active"
                          : ""
                      }
                    >
                      <img className="icon" src={lessonIcon} alt="Lessons" />
                      Lessons
                    </Link>
                  </li>

                  {ACCOUNT_OBJ.appMetadata.CHPPlan === "3" ||
                  ACCOUNT_OBJ.appMetadata.CHPPlan === "U" ? (
                    <li>
                      <Link
                        to="/account/schedule/"
                        className={
                          this.props.location.pathname === "/account/schedule/"
                            ? "active"
                            : ""
                        }
                      >
                        <img
                          className="icon"
                          src={appointmentIcon}
                          alt="Dashboard"
                        />
                        Appointments
                      </Link>
                    </li>
                  ) : null}
                  {/*}<li>
                    <a href="#">
                      <img
                        className="icon"
                        src={communityIcon}
                        alt="Dashboard"
                      />
                      Community
                    </a>
                  </li>
                  */}
                  <li>
                    <Link
                      to="/account/help/"
                      className={
                        this.props.location.pathname === "/account/help/"
                          ? "active"
                          : ""
                      }
                    >
                      <img className="icon" src={infoIcon} alt="Account Help" />
                      Help
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="column is-11">
              <div className="account-body">
                <section className="section">
                  <Router>
                    <Dashboard
                      path="/account/"
                      user={ACCOUNT_OBJ}
                      isOnboardingState={this.state.isEntryState}
                      isExitState={this.state.isExitState}
                      programInfo={this.state.programInfo}
                      setAccountStateFromChild={this.setAccountStateFromChild}
                    />
                    <Lesson path="/account/lesson/" user={ACCOUNT_OBJ} />
                    <Schedule path="/account/schedule/" user={ACCOUNT_OBJ} />
                    <Help path="/account/help/" user={ACCOUNT_OBJ} />
                    <Assessments
                      path="/account/assessments/"
                      user={ACCOUNT_OBJ}
                      isOnboardingState={this.state.isEntryState}
                      isExitState={this.state.isExitState}
                      programInfo={this.state.programInfo}
                      setAccountStateFromChild={this.setAccountStateFromChild}
                    />
                    {/* <LessonDev path="/account/dev" /> */}
                  </Router>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
