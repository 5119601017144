const FUANADB_READ_PROGRAM_INFO_ENDPOINT =
  "/.netlify/functions/read-program-info";
const FUANADB_WRITE_PROGRAM_INFO_ENDPOINT =
  "/.netlify/functions/write-program-info";
const FUANADB_CREATE_NEW_PROGRAM_INFO_ENDPOINT =
  "/.netlify/functions/create-new-program-info";
/* 
  Read the document in the collection
*/

export const faunaReadProgramInfo = async (token, email) => {
  if (!email) {
    console.log(
      "%c faunaReadProgramInfo() Email Not Provided.",
      "background-color: red; color: white; padding: 3px;"
    );
    return;
  }
  try {
    let response = await fetch(FUANADB_READ_PROGRAM_INFO_ENDPOINT, {
      headers: {
        authorization: "Bearer " + token,
      },
      method: "post",
      body: JSON.stringify({
        email: email,
      }),
    });

    let data = await response.json();
    let programInfo = data.data[0].data;
    let ref = data.data[0].ref["@ref"].id;
    programInfo.ref = ref;

    console.log(
      "%c faunaReadProgramInfo() Successful.",
      "background-color: green; color: white; padding: 3px;"
    );
    return programInfo;
  } catch (err) {
    console.log(
      "%c faunaReadProgramInfo() Failed.",
      "background-color: red; color: white; padding: 3px;"
    );
    console.log(err);
  }
};

/* 
  Update an existing document in a collection 
*/

export const faunaWriteProgramInfo = async (token, ref, dataToBeUpdated) => {
  if (!ref) {
    console.log(
      "%c faunaWriteProgramInfo() Ref Not Provided.",
      "background-color: red; color: white; padding: 3px;"
    );
    return;
  }

  if (!dataToBeUpdated) {
    console.log(
      "%c faunaWriteProgramInfo() No Data Provided.",
      "background-color: red; color: white; padding: 3px;"
    );
    return;
  }
  try {
    let response = await fetch(FUANADB_WRITE_PROGRAM_INFO_ENDPOINT, {
      headers: {
        authorization: "Bearer " + token,
      },
      method: "post",
      body: JSON.stringify({
        dataToBeUpdated: dataToBeUpdated,
        ref: ref,
      }),
    });
    let data = await response.json();
    console.log(
      "%c faunaWriteProgramInfo() Successful.",
      "background-color: green; color: white; padding: 3px;"
    );
    return data;
  } catch (err) {
    console.log(
      "%c faunaWriteProgramInfo() Failed.",
      "background-color: red; color: white; padding: 3px;"
    );
    console.log(err);
  }
};

/* 
  Create a new document in the collection for new users.
*/

export const fuanaCreateNewProgramInfo = async (token, email) => {
  if (!email) {
    console.log(
      "%c faunaCreateNewProgramInfo() Email Not Provided.",
      "background-color: red; color: white; padding: 3px;"
    );
    return;
  }
  try {
    let response = await fetch(FUANADB_CREATE_NEW_PROGRAM_INFO_ENDPOINT, {
      headers: {
        authorization: "Bearer " + token,
      },
      method: "post",
      body: JSON.stringify({
        email: email,
      }),
    });

    let data = await response.json();
    let programInfo = data.data;
    let ref = data.ref["@ref"].id;
    programInfo.ref = ref;

    console.log(
      "%c faunaCreateNewProgramInfo() Successful.",
      "background-color: green; color: white; padding: 3px;"
    );
    return programInfo;
  } catch (err) {
    console.log(
      "%c faunaCreateNewProgramInfo() Failed.",
      "background-color: red; color: white; padding: 3px;"
    );
    console.log(err);
  }
};
