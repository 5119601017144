import React from "react";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { v4 } from "uuid";

export class LessonListTemplate extends React.Component {
  finalizeLessonListData() {
    if (this.props.user.currentWeek > 25) {
      return false;
    }

    var lessonsArr = this.props.lessons;
    lessonsArr.length = this.props.user.currentWeek + 1;
  }

  render() {
    this.finalizeLessonListData();

    var lessons = this.props.lessons;

    if (lessons[0].lessonCode === "L00") {
      lessons = lessons.reverse();
    }

    return (
      <React.Fragment>
        {lessons.map((lesson, index) => {
          if (index === 0) {
            return (
              <Link
                key={v4()}
                className="featured-lesson"
                state={{ lessonCode: lesson.lessonCode }}
                to="/account/lesson/"
              >
                <div className="columns w-full">
                  <div className="column is-4 is-vcentered is-centered">
                    <img src={lesson.image} alt={lesson.subtitle} />
                  </div>
                  <div className="column">
                    <div className="desc">
                      <p className="heading has-text-grey">{lesson.subtitle}</p>
                      <p
                        className="is-size-3 has-text-weight-bold has-text-black"
                        style={{ lineHeight: "40px", padding: "10px 0" }}
                      >
                        {lesson.title}
                      </p>
                      <p className="subheading has-text-grey-dark">
                        {lesson.blurb}
                      </p>
                      <div className="meta"></div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          } else {
            return (
              <Link
                key={v4()}
                className="previous-lesson"
                state={{ lessonCode: lesson.lessonCode }}
                to="/account/lesson/"
              >
                <div className="level">
                  <div className="level-left">
                    <div className="level-item">
                      <img src={lesson.image} alt={lesson.subtitle} />
                    </div>
                    <div className="level-item">
                      <div className="desc">
                        <p className="heading has-text-grey">
                          {lesson.subtitle}
                        </p>
                        <p className="is-size-4 has-text-weight-bold has-text-grey-dark">
                          {lesson.title}
                        </p>
                        <div className="meta"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          }
        })}
      </React.Fragment>
    );
  }
}

export default class LessonList extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query LessonsJSON {
            allDataJson {
              edges {
                node {
                  lessons {
                    subtitle
                    title
                    image
                    lessonCode
                    type
                    blurb
                  }
                }
              }
            }
          }
        `}
        render={data => (
          /*
            Renders LessonListTemplate Populated With Static Query Data
          */
          <LessonListTemplate
            lessons={data.allDataJson.edges[0].node.lessons}
            user={this.props.user}
          />
        )}
      />
    );
  }
}
