import React from "react";
import { navigate } from "@reach/router";

import LessonList from "../components/LessonList";
import Effort from "./account/Effort";

export default class Dashboard extends React.Component {
  componentDidMount() {
    /*
      ***
      Include Reroute Logic to supercede the dashboard
      ***
    */
    console.log("Checking for Reroute...");

    if (
      (this.props.isOnboardingState &&
        window.sessionStorage &&
        window.sessionStorage.getItem("isOnboardingComplete") !== "true") ||
      (this.props.isExitState &&
        window.sessionStorage &&
        window.sessionStorage.getItem("isExitComplete") !== "true")
    ) {
      console.log("Rerouting to Assessments.");
      navigate("/account/assessments/");
      return;
    }

    console.log("No Reroute found.");
  }

  render() {
    /* Object Reference

      app_metadata: {
        firstName,
        lastName,
        hasCHPAccess,
        CHPPlan,
        CHPStartDate,
        isCapsuleVerified,
        isCHPSuspended
      },
      user_metadata: {
        entryComplete,
        exitComplete,
        efort: {
          "month1": "false",
          ...
        }
      }

    */

    var planName;
    if (this.props.user.appMetadata.CHPPlan === "1") {
      planName = "Self-Guided";
    } else if (this.props.user.appMetadata.CHPPlan === "2") {
      planName = "Community Supported";
    } else if (this.props.user.appMetadata.CHPPlan === "3") {
      planName = "Comprehensive";
    }

    const EFFORT_TRIGGER_WEEKS = {
      4: "e1",
      8: "e2",
      12: "e3",
      16: "e4",
      20: "e5",
      24: "e6",
    };

    /*
      Users qualify for effort entry if it is the trigger week
      or if it is the following week. This gives the user
      some additonal time.
    */

    const CURRENT_EFFORT_TRIGGER =
      EFFORT_TRIGGER_WEEKS[this.props.user.currentWeek] ||
      EFFORT_TRIGGER_WEEKS[this.props.user.currentWeek - 1];

    console.log("CURRENT_EFFORT_TRIGGER", CURRENT_EFFORT_TRIGGER);

    console.log(this.props);
    const HAS_VALID_EFFORT_OPENING =
      CURRENT_EFFORT_TRIGGER &&
      this.props.programInfo.effort[CURRENT_EFFORT_TRIGGER].score === false;

    console.log("HAS_VALID_EFFORT_OPENING", HAS_VALID_EFFORT_OPENING);

    return (
      <React.Fragment>
        <div className="columns">
          <div className="column">
            {HAS_VALID_EFFORT_OPENING ? (
              <h1 className="title is-size-1 has-text-black has-text-weight-bold">
                How Did You Do <br /> This Month?
              </h1>
            ) : (
              <h1
                key="1"
                className="title is-size-1 has-text-black has-text-weight-bold"
              >
                Cognitive <br></br> Health Program <br></br> Lessons
              </h1>
            )}
          </div>
          <div
            className="column is-narrow"
            style={{ marginTop: "10px", marginRight: "30px" }}
          >
            <div className="heading">
              {/* <span className="tag is-light">{planName}</span> */}
            </div>
          </div>
        </div>
        {!HAS_VALID_EFFORT_OPENING ? (
          <div className="columns">
            <div className="column is-6">
              <p
                className="dash-msg has-text-grey"
                style={{ marginBottom: "20px" }}
              >
                Below you will find lessons for your Cognitive Health Program.
                <br />
                New lessons will become available each week for the duration of
                your program. Your next lesson will appear in your feed on{" "}
                <strong> {}.</strong>
              </p>
            </div>
          </div>
        ) : null}

        <div className="lessons-block">
          {!this.props.isOnboardingState && HAS_VALID_EFFORT_OPENING ? (
            <Effort
              user={this.props.user}
              entry={CURRENT_EFFORT_TRIGGER}
              programInfo={this.props.programInfo}
              setAccountStateFromChild={this.props.setAccountStateFromChild}
            />
          ) : (
            <LessonList key="1" user={this.props.user} />
          )}
        </div>
      </React.Fragment>
    );
  }
}
