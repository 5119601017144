import React from "react";

const ErrorMsg = (props) => {
  console.log(props);
  return (
    <article
      className="message is-danger"
      style={{
        marginBottom: "100px",
        marginTop: "20px",
        marginLeft: "20px",
      }}
    >
      <div className="message-body">
        <strong>{props.title ? props.title : "Error"}</strong>
        <p>
          {props.msg ? props.msg : null} {props.err ? props.err : null}
        </p>
      </div>
    </article>
  );
};

export default ErrorMsg;
