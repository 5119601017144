import React from "react";
import { Link } from "gatsby";

export default class Help extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1 className="title is-size-1 has-text-black has-text-weight-bold">
          Help<br></br>Center
        </h1>
        <h3 className="is-size-3">General Information</h3>
        <div style={{ margin: "20px 0px" }}>
          <strong className="is-2 has-text-grey-dark">
            When will I see my next lesson?
          </strong>
          <p className="has-text-grey">
            Each week throughout your program, a new lesson will appear on your
            <Link to="/account/">
              <strong> Dashboard</strong>
            </Link>
            . You will receive an email that will remind you that your newest
            lesson is available. When you receive that email, click on the link
            and login. All of your previous lessons will also still be available
            to you for the duration of your program so you can continue to
            review them.
          </p>
          <hr />
        </div>

        {this.props.user.appMetadata.CHPPlan === "2" ||
        this.props.user.appMetadata.CHPPlan === "3" ? (
          <React.Fragment>
            <h3 className="is-size-3">Office Hours</h3>

            <div style={{ margin: "20px 0px" }}>
              <strong className="has-text-grey-dark">
                How do I sign up for Office Hours?
              </strong>
              <p className="has-text-grey">
                You will receive an email notification with the time and date of
                each upcoming Office Hours. A URL to the Office Hours livestream
                will be present in that email. Click that link to be taken to
                the livestream page. The RDN hosting the Office Hours will be
                visible at the time stated.
              </p>
              <hr />
            </div>

            <div style={{ margin: "20px 0px" }}>
              <strong className="has-text-grey-dark">
                How do I access an Office Hours session?
              </strong>
              <p className="has-text-grey">
                To join an Office Hours session, simply click the URL in the
                email you receive prior to the Office Hours session.
              </p>
              <hr />
            </div>

            <div style={{ margin: "20px 0px" }}>
              <strong className="has-text-grey-dark">
                Do I have to actively participate in Office Hours in order to
                attend?
              </strong>
              <p className="has-text-grey">
                No. You are welcome to attend even if you have not submitted any
                questions. During Office Hours, you will see and hear the
                dietitian, but we cannot see you or hear you, so it is
                completely confidential and private. We invite you to join us
                just to listen in to questions that have been submitted by other
                participants.
              </p>
              <hr />
            </div>

            <div style={{ margin: "20px 0px" }}>
              <strong className="has-text-grey-dark">
                How do I submit a question to be answered during Office Hours?
              </strong>
              <p className="has-text-grey">
                You are welcome to email your questions to
                info@amosinstitute.com. When you email your question, please
                indicate the date and time of the Office Hours that you have
                signed up for. We will do our very best to answer your questions
                during that Office Hours session.
              </p>
              <hr />
            </div>

            <div style={{ margin: "20px 0px" }}>
              <strong className="has-text-grey-dark">
                What types of questions can I submit to be answered during
                Office Hours?
              </strong>
              <p className="has-text-grey">
                Office Hours is the perfect time to ask general questions about
                the protocol. For example, you can ask about product
                recommendations, advice about implementing certain aspects of
                the protocol, clarifications on aspects of the protocol.
              </p>
              <hr />
            </div>
          </React.Fragment>
        ) : null}

        {this.props.user.appMetadata.CHPPlan === "3" ? (
          <React.Fragment>
            <h3 className="is-size-3">
              Scheduling Your One-On-One Consultation
            </h3>
            <div style={{ margin: "20px 0px" }}>
              <strong className="has-text-grey-dark">
                How do I make my monthly one-on-one dietitian consultation?
              </strong>
              <p className="has-text-grey">
                While logged into your Amos Institute account, click{" "}
                <Link to="/account/schedule/">
                  <strong>Appointments</strong>
                </Link>{" "}
                from the menu on the left side of the screen. On this page you
                will be able to access our scheduling system. Each month you
                should select a date and time on this scheduling system to sign
                up for your one-on-one dietitian consultation. Once you sign up,
                you will receive a confirmation email with the subject
                "Invitation: [Your Name] and Amos Institute @ [Date and Time of
                your Appointment (PST)]". If you do not receive this email after
                scheduling your appointment, check your spam/junk folder. You do
                not need to respond to this confirmation email or take any other
                action. Your appointment has been confirmed. Within 24 hours of
                scheduling your one-on-one consultation, you will receive
                another email that contains a link for you to access the meeting
                at the date and time you have selected. The subject of the email
                will be "Updated Invitation: [Your Name] and Amos Institute @
                [Date and Time of your Appointment (PST)]." In the middle of the
                email you will see the words "Joining Info Changed: [the link
                you will use to connect to your consultation]." At the date and
                time of your scheduled appointment, click that link and you will
                be automatically connected to your Google Meet consultation. You
                are responsible for scheduling your one-on-one consultation each
                month. Any consultations that you do not schedule will be
                forfeited.
              </p>
              <hr />
            </div>

            <div style={{ margin: "20px 0px" }}>
              <strong className="has-text-grey-dark">
                How do I use Google Meet for my consultation?
              </strong>
              <p className="has-text-grey">
                All of your private consultations with your dietitian will be
                held using the Google Meet program, a free online video
                conferencing platform. Google Meet is a very user friendly video
                conferencing platform. All you need is a computer with a strong
                internet connection. After you signed up for your one-on-one
                consultation, you should have received a confirmation email.
                That email contains a link to access your one-on-one
                consultation. When you click the link to attend your
                consultation, you will be prompted to turn on your computer’s
                video camera and activate your computer’s microphone. Please
                click ‘allow’ on both of these prompts so that your dietitian is
                able to see and hear you. If you are having issues, follow the
                instruction for{" "}
                <a
                  href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en&oco=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  Chrome
                </a>{" "}
                or{" "}
                <a
                  href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#w_using-prompts-to-allow-or-block-camera-and-microphone-permissions-for-a-site"
                  target="_blank"
                  rel="noreferrer"
                >
                  Firefox
                </a>
                .
              </p>
              <hr />
            </div>

            <div style={{ margin: "20px 0px" }}>
              <strong className="has-text-grey-dark">
                How do I change my one-on-one dietitian consultation?
              </strong>
              <p className="has-text-grey">
                After you have signed up for your one-on-one dietitian
                consultation, you will receive an email confirmation. In the
                body of that confirmation email, you will find a link that you
                can use to reschedule your consultation if needed. Keep in mind
                that you must reschedule a consultation at least 24 hours before
                the scheduled time of the consultation. Late cancellations will
                result in the forfeiting of that appointment.
              </p>
              <hr />
            </div>

            <div style={{ margin: "20px 0px" }}>
              <strong className="has-text-grey-dark">
                What if I need to cancel my one-on-one dietitian consultation?
              </strong>
              <p className="has-text-grey">
                You are welcome to cancel and reschedule one-on-one
                consultations up to 24 hours before the consultation time. Any
                consultations cancelled and not rescheduled in the appropriate
                time frame will be forfeited.
              </p>
              <hr />
            </div>

            <div style={{ margin: "20px 0px" }}>
              <strong className="has-text-grey-dark">
                What's the difference between Office Hours and my monthly
                one-on-one consultations?
              </strong>
              <p className="has-text-grey">
                Office Hours are a weekly opportunity to view a live streaming
                event from one of our RDNs. Prior to Office Hours, you will have
                the opportunity to submit questions that can be answered during
                the livestream. The questions that can be answered during Office
                Hours must be general to protect the confidentiality of all
                those attending. Examples of topics that are well suited to
                Office Hours include those about strategies to implement various
                aspects of the protocol, challenges that arise while following
                the protocol, advice for product recommendations, etc. The
                monthly one-on-one consultations are a chance for you to meet
                one-on-one with your Amos Institute dietitian. You may schedule
                your one-on-one consultation each month via the Appointments tab
                on the left hand side of your Amos Institute dashboard. During
                your monthly one-on-one consultation, you may ask all of the
                questions that you have that are specific to you- your lab work,
                your genetics, and the individualization of the protocol to meet
                your particular needs.
              </p>
              <hr />
            </div>
          </React.Fragment>
        ) : null}

        <article className="message" style={{ margin: "50px 0px" }}>
          <div className="message-body">
            <strong>Need more assistance?</strong>
            <p>
              If you are having any issues or have questions about your account,
              please send us an email at <u>info@amosinstitute.com</u>.
            </p>
          </div>
        </article>
      </React.Fragment>
    );
  }
}
