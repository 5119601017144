import React from "react";
import { tokens } from "../../utils/auth";
import { faunaWriteProgramInfo } from "../../utils/fauna";
import moment from "moment";
import ErrorMsg from "./ErrorMsg";

export default class Effort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      score: false,
      isLoading: false,
      error: false,
    };
  }

  handleClick(e) {
    var selection = e.target.value;
    e.target.disabled = true; // Prevents double click

    console.log(this.props.user);
    console.log("Entry", this.props.entry);
    console.log(selection);
    var formattedData = `{ "${this.props.entry}" : ${selection} }`;
    var json = JSON.parse(formattedData);
    this.setState({ data: json });

    this.sendEffortData(selection);
    this.setState({ score: selection });
    window.scrollTo(0, 0);
  }

  sendEffortData(score) {
    this.setState({ isLoading: true });

    const API = "https://hooks.zapier.com/hooks/catch/2825855/o1bed8l/";

    fetch(API, {
      method: "POST",
      body: JSON.stringify({
        date: this.props.user.currentDate,
        client: this.props.user.email,
        age: this.props.user.appMetadata.clientAge,
        gender: this.props.user.appMetadata.clientGender,
        plan: this.props.user.appMetadata.CHPPlan,
        effortEntry: this.props.entry,
        effortScore: score,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Posting data has failed...");
        }
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        this.setState({ error: true, isLoading: false });
      });
  }

  updateMetaData() {
    let effortData = {};
    effortData[this.props.entry] = {};
    effortData[this.props.entry].score = this.state.score;
    effortData[this.props.entry].date = moment().format("YYYY-MM-DD");

    console.log(">>> EffortData", effortData);

    faunaWriteProgramInfo(tokens.accessToken, this.props.programInfo.ref, {
      effort: effortData,
    })
      .then((data) => {
        console.log(data);
        let effort = { ...this.props.programInfo.effort };
        effort[this.props.entry].score = this.state.score;
        effort[this.props.entry].date = moment().format("YYYY-MM-DD");

        this.props.setAccountStateFromChild({
          programInfo: { effort: effort },
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: true, isLoading: false });
      });
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorMsg
          msg="We apologize for the in inconvenience. Please refresh the page and
        try again. If this issue persists, please send us an email at
        info@amosinstitute.com."
          title="Error Determining Effort State"
        />
      );
    }

    if (this.state.score >= 8) {
      return (
        <article
          className="message is-success"
          style={{ marginBottom: "100px" }}
        >
          <div className="message-body">
            <p style={{ marginBottom: "20px" }}>
              Congratulations! The changes you are making and the new healthy
              habits you are incorporating are directly impacting your
              metabolism and your brain function. It takes time to see cognitive
              improvements, so keep up your great work! You inspire us all!
            </p>
            <button
              onClick={this.updateMetaData.bind(this)}
              className="button is-success"
            >
              Return to Lessons
            </button>
          </div>
        </article>
      );
    }

    if (this.state.score >= 5) {
      return (
        <article
          className="message is-success"
          style={{ marginBottom: "100px" }}
        >
          <div className="message-body">
            <p style={{ marginBottom: "20px" }}>
              Good job! You are doing well and we know how much work it is to
              fully implement the Bredesen Protocol into your life and make all
              of these new healthy habits a consistent part of your lifestyle.
              We encourage you to push even harder so that you are incorporating
              most, if not all, of the elements into your daily routine. You're
              almost there! Be sure to utilize the additional resources provided
              in each of your lessons for helpful recipes and articles that will
              make the implementation of the program easier.
            </p>
            <button
              onClick={this.updateMetaData.bind(this)}
              className="button is-success"
            >
              Return to Lessons
            </button>
          </div>
        </article>
      );
    }

    if (this.state.score) {
      return (
        <article
          className="message is-success"
          style={{ marginBottom: "100px" }}
        >
          <div className="message-body">
            <p style={{ marginBottom: "20px" }}>
              Hang in there! We understand how difficult the Bredesen Protocol
              can be to implement into your lifestyle. Maybe this was just an
              all-around difficult month for you. Please don't be discouraged.
              Keep at it and be sure to watch the lessons more than once if
              necessary and be sure to look into all of the resources that are
              provided with each lesson. Many of these resources are designed to
              help make implementing the nutrition and lifestyle components of
              the Bredesen Protocol easier.
            </p>
            <button
              onClick={this.updateMetaData.bind(this)}
              className="button is-success"
            >
              Return to Dashboard
            </button>
          </div>
        </article>
      );
    }

    return (
      <React.Fragment>
        <div className="columns">
          <div className="column is-7">
            <p
              className="dash-msg has-text-grey"
              style={{ marginBottom: "20px" }}
            >
              We know that implementation of the Bredesen Protocol can be
              challenging. There are so many things to do, but it is the
              combination of all of these steps that lead to improved cognition.
              In order to help you keep track of your effort, every month we ask
              you to rate your compliance with the program in the past month.
            </p>
          </div>
        </div>
        <div className="effort-wrapper">
          <div className="notification is-info is-light">
            <strong>
              Click on the score that best describes your compliance with the
              program and effort this past month.
              <br />
              We encourage you to be honest as this will help us and you in
              tracking your progress.
            </strong>
          </div>
          <div className="score-selection">
            <label className="radio">
              <input
                type="radio"
                name="effort"
                value="10"
                onClick={this.handleClick.bind(this)}
              />
              10: You demonstrated <strong>excellent</strong> compliance and did{" "}
              <strong>everything</strong> exactly as directed on your program
              this month.
            </label>
            <label className="radio">
              <input
                type="radio"
                name="effort"
                value="9"
                onClick={this.handleClick.bind(this)}
              />
              9: You demonstrated <strong>really great</strong> compliance and
              did <strong>almost everything</strong> as directed on your program
              this month.
            </label>
            <label className="radio">
              <input
                type="radio"
                name="effort"
                value="8"
                onClick={this.handleClick.bind(this)}
              />
              8: You demonstrated <strong>really good</strong> compliance and
              did <strong>most things</strong> as directed on your program this
              month.
            </label>
            <label className="radio">
              <input
                type="radio"
                name="effort"
                value="7"
                onClick={this.handleClick.bind(this)}
              />
              7: You demonstrated <strong>good</strong> compliance and did{" "}
              <strong>more than half of things</strong> as directed on your
              program this month.
            </label>
            <label className="radio">
              <input
                type="radio"
                name="effort"
                value="6"
                onClick={this.handleClick.bind(this)}
              />
              6: You demonstrated <strong> above average</strong> compliance and
              did <strong>half of things</strong> as directed on your program
              this month.
            </label>
            <label className="radio">
              <input
                type="radio"
                name="effort"
                value="5"
                onClick={this.handleClick.bind(this)}
              />
              5: You demonstrated <strong> average</strong> compliance and did{" "}
              <strong>less than half of things</strong> as directed on your
              program this month.
            </label>
            <label className="radio">
              <input
                type="radio"
                name="effort"
                value="4"
                onClick={this.handleClick.bind(this)}
              />
              4: You demonstrated <strong> below average</strong> compliance and
              did <strong>some things</strong> as directed on your program this
              month.
            </label>
            <label className="radio">
              <input
                type="radio"
                name="effort"
                value="3"
                onClick={this.handleClick.bind(this)}
              />
              3: You <strong> were not successful</strong> with compliance but
              did <strong>a few things</strong> as directed on your program this
              month.
            </label>
            <label className="radio">
              <input
                type="radio"
                name="effort"
                value="2"
                onClick={this.handleClick.bind(this)}
              />
              2: You <strong> did not comply</strong> with compliance but did{" "}
              <strong>one or two things</strong> as directed on your program
              this month.
            </label>
            <label className="radio">
              <input
                type="radio"
                name="effort"
                value="1"
                onClick={this.handleClick.bind(this)}
              />
              1: You <strong> did not comply</strong> with your program this
              month.
            </label>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
