import React from "react";
import { tokens } from "../../utils/auth";
import { faunaWriteProgramInfo } from "../../utils/fauna";
import { navigate } from "@reach/router";
import { Link } from "gatsby";
import { login } from "../../utils/auth";
import moment from "moment";
import ErrorMsg from "./ErrorMsg";

export default class Assessments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoading: false,
      error: false,
    };
  }

  sendTestData(msqScore, mocaScore) {
    this.setState({ isLoading: true });

    const API = "https://hooks.zapier.com/hooks/catch/2825855/oh6uvkv/";
    const PLAN_STAGE = this.props.isOnboardingState === true ? "Entry" : "Exit";

    fetch(API, {
      method: "POST",
      body: JSON.stringify({
        date: this.props.user.currentDate,
        client: this.props.user.email,
        age: this.props.user.appMetadata.clientAge,
        gender: this.props.user.appMetadata.clientGender,
        plan: this.props.user.appMetadata.CHPPlan,
        entryExit: PLAN_STAGE,
        msqScore: msqScore,
        mocaScore: mocaScore,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Posting data has failed...");
        }
      })
      .then((data) => {
        console.log(data);
        this.updateMetaData(msqScore, mocaScore);
      })
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    if (document.querySelector(".no-assessment-msg")) {
      return false;
    }

    console.log("isOnbardingState ", this.props.isOnboardingState);
    console.log("isExitState", this.props.isExitState);

    const msqSection = document.querySelector(".msq-section");
    const msqSubmitBtn = document.querySelector(".msq-section button");
    const msqSmallTxt = document.querySelector(".msq-section small");
    // Bind MSQ Button Click
    msqSubmitBtn.addEventListener(
      "click",
      function() {
        var msqInput = document.querySelector(".msq-section input");
        this.handleInputSubmit(
          msqSection,
          msqInput,
          msqSubmitBtn,
          msqSmallTxt,
          "msq"
        );
      }.bind(this)
    );

    const mocaSection = document.querySelector(".moca-section");
    const mocaSubmitBtn = document.querySelector(".moca-section button");
    const mocaSmallTxt = document.querySelector(".moca-section small");

    // Bind MoCA button Click
    mocaSubmitBtn.addEventListener(
      "click",
      function() {
        var mocaInput = document.querySelector(".moca-section input");
        this.handleInputSubmit(
          mocaSection,
          mocaInput,
          mocaSubmitBtn,
          mocaSmallTxt,
          "moca"
        );
      }.bind(this)
    );
  }

  handleInputSubmit(sectionElem, inputElem, btnElem, textElem, testName) {
    var inputValue = inputElem.value.trim();

    if (inputValue.length > 3) {
      this.showInputError(textElem, inputElem);
      return false;
    }

    var isValidScore =
      testName === "msq"
        ? this.isValidMsqScore(inputValue)
        : this.isValidMocaScore(inputValue);

    if (inputValue !== "" && isValidScore) {
      btnElem.disabled = true;
      sectionElem.classList.add("collapse");
      sectionElem.setAttribute("data-complete", "true");
      sectionElem.setAttribute("data-score", inputValue);
      this.displayCompletionMsg(testName);
      this.checkIfAllAssessmentsAreDone();
    } else {
      this.showInputError(textElem, inputElem);
    }
  }

  showInputError(textElem, inputElem) {
    inputElem.classList.add("input-error");
    textElem.style.cssText += "color: #D0021B !important";
    textElem.innerHTML = "Please enter a valid score.";
  }

  displayCompletionMsg(testName) {
    const msgContainer = document.querySelector("#success-msg-container");

    if (msgContainer.style.display === "none") {
      msgContainer.style.display = "block";
    }

    const testMsg = document.querySelector(
      "#success-msg-container " + "." + testName.toLowerCase() + "-success"
    );
    testMsg.style.display = "block";
  }

  isValidMsqScore(score) {
    return score >= 0 && score <= 284;
  }

  isValidMocaScore(score) {
    return score >= 0 && score <= 30;
  }

  checkIfAllAssessmentsAreDone() {
    var isMsqComplete = document
      .querySelector(".msq-section")
      .getAttribute("data-complete");
    var isMocaComplete = document
      .querySelector(".moca-section")
      .getAttribute("data-complete");

    console.log("isMsqComplete", isMsqComplete);
    console.log("isMocaComplete", isMocaComplete);

    // Reload the page
    if (isMsqComplete && isMocaComplete) {
      var msqScore = document
        .querySelector(".msq-section")
        .getAttribute("data-score");

      var mocaScore = document
        .querySelector(".moca-section")
        .getAttribute("data-score");

      this.sendTestData(msqScore, mocaScore);
    }
  }

  updateMetaData(msqScore, mocaScore) {
    console.log("Updating metadata...");

    if (this.props.isOnboardingState) {
      faunaWriteProgramInfo(tokens.accessToken, this.props.programInfo.ref, {
        onboarding: {
          isComplete: true,
          completionDate: moment().format("YYYY-MM-DD"),
          msq: {
            score: msqScore,
          },
          moca: {
            score: mocaScore,
          },
        },
      }).then(
        function(data) {
          let programInfo = { ...this.props.programInfo };
          programInfo.onboarding = data.data.onboarding;

          console.log(programInfo);
          this.props.setAccountStateFromChild({
            programInfo,
            isEntryState: false,
          });
          navigate("/account/");
        }.bind(this)
      );
    } else if (this.props.isExitState) {
      faunaWriteProgramInfo(tokens.accessToken, this.props.programInfo.ref, {
        offboarding: {
          isComplete: true,
          completionDate: moment().format("YYYY-MM-DD"),
          msq: {
            score: msqScore,
          },
          moca: {
            score: mocaScore,
          },
        },
      }).then(
        function(data) {
          let programInfo = { ...this.props.programInfo };
          programInfo.onboarding = data.data.onboarding;

          console.log(programInfo);
          this.props.setAccountStateFromChild({
            programInfo,
            isExitState: false,
          });
          navigate("/account/");
        }.bind(this)
      );
    }
  }

  render() {
    if (!this.props.isOnboardingState && !this.props.isExitState) {
      return (
        <ErrorMsg
          title="You have no pending assessments."
          msg="Please refresh the page."
        />
      );
    }

    return (
      <>
        <div className="columns">
          <div className="column">
            <h1 className="title is-size-1 has-text-black has-text-weight-bold">
              Symptoms & <br></br>Cognitive Assessment
            </h1>
            {!this.props.user.userMetadata.entryComplete ? (
              <>
                <p className="has-text-grey">
                  Before we we get started with your new program, please
                  complete the MSQ & MoCA assessments below.
                </p>
                <hr />
              </>
            ) : null}

            {this.props.user.userMetadata.entryComplete &&
            !this.props.user.userMetadata.exitComplete &&
            this.props.user.currentWeek > 25 ? (
              <p className="has-text-grey">
                <strong>Congratulations! </strong>You have nearly completed your
                Amos Institute program. Before you continue to your final
                lesson, please take the following assessments like you did when
                your program first started.
              </p>
            ) : null}
          </div>
        </div>
        <div id="success-msg-container" style={{ display: "none" }}>
          <article
            className="message is-success"
            style={{
              marginBottom: "40px",
            }}
          >
            <div className="message-body">
              <div className="msq-success" style={{ display: "none" }}>
                <strong>&#10004; MSQ Assessment Complete.</strong>
              </div>
              <div className="moca-success" style={{ display: "none" }}>
                <strong>&#10004; MoCA Assessment Complete.</strong>
              </div>
            </div>
          </article>
        </div>
        {this.state.error !== null ? (
          <>
            <section className="msq-section">
              <div
                className="has-text-grey-dark has-text-weight-semibold is-size-5"
                style={{ marginBottom: "25px" }}
              >
                Medical Symptoms Questionnaire
                <span className="has-text-grey"> (MSQ)</span>
              </div>

              <div className="columns">
                <div className="column">
                  <p>
                    This test identifies symptoms that help to detect the
                    underlying causes of illness and will help you track your
                    improvement over time. You can either print this form out
                    and write your scores on it, or you can view it on your
                    computer and write your scores on a separate piece of paper
                    and then total them up when you are finished. All we need at
                    the end is your final score.
                  </p>
                </div>
              </div>

              <div className="control" style={{ marginBottom: "20px" }}>
                <a
                  href="https://amosinstitute-public-assets.s3-us-west-2.amazonaws.com/assessments/Medical+Symptoms+Questionnaire.pdf"
                  target="_blank"
                  className="button is-primary"
                >
                  Open Test
                </a>
              </div>

              <small
                style={{ marginBottom: "10px", display: "block" }}
                className="has-text-grey"
              >
                Enter your MSQ score here:
              </small>

              <div
                className="field has-addons"
                style={{ marginBottom: "25px", width: "250px" }}
              >
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="MSQ Score"
                  />
                </div>
                <div className="control">
                  <button className="button is-primary">Submit</button>
                </div>
              </div>

              <hr />
            </section>
            <section className="moca-section">
              <div
                className="has-text-grey-dark has-text-weight-semibold is-size-5"
                style={{ marginBottom: "25px" }}
              >
                Montreal Cognitive Assessment
                <span className="has-text-grey"> (MoCA)</span>
              </div>

              <div className="columns">
                <div className="column">
                  <p>
                    You will need a spouse, caretaker, friend or other person to
                    assist you in taking this test. <br></br>
                    <br></br> The MoCA assessment is a widely used screening
                    tool for measuring cognitive performance and detecting mild
                    cognitive impairment. It provides insight into a person's
                    degree and type of cognitive performance in multiple areas.
                    You cannot take this assessment by yourself. The MoCA must
                    be administered to you by someone. This can be a spouse,
                    caretaker, friend or other person who can carefully read the
                    instructions to you. You should NOT read this test in
                    advance. Please click the
                    <strong> Open Test</strong> button and print it out and give
                    it to the person who will be administering the test to you.
                    If you read the test or the instructions in advance it will
                    not provide an accurate assessment.
                  </p>
                </div>
              </div>

              {this.props.user.userMetadata.entryComplete &&
              !this.props.user.userMetadata.exitComplete &&
              this.props.user.currentWeek > 25 ? (
                <div className="control" style={{ marginBottom: "20px" }}>
                  <a
                    href="https://amosinstitute-public-assets.s3-us-west-2.amazonaws.com/assessments/MoCA+Assistant+Instructions+-+End+of+Program.pdf"
                    target="_blank"
                    className="button is-inst-blue"
                  >
                    Open Test
                  </a>
                </div>
              ) : (
                <div className="control" style={{ marginBottom: "20px" }}>
                  <a
                    href="https://amosinstitute-public-assets.s3-us-west-2.amazonaws.com/assessments/MoCA+Assistant+Instructions+-+Start+of+Program.pdf"
                    target="_blank"
                    className="button is-inst-blue"
                  >
                    Open Test
                  </a>
                </div>
              )}

              <small
                style={{ marginBottom: "10px", display: "block" }}
                className="has-text-grey"
              >
                Enter your MoCA score here:
              </small>

              <div
                className="field has-addons"
                style={{ marginBottom: "25px", width: "250px" }}
              >
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="MoCA Score"
                  />
                </div>
                <div className="control">
                  <button className="button is-inst-blue">Submit</button>
                </div>
              </div>
            </section>

            <article className="message" style={{ marginTop: "80px" }}>
              <div className="message-body">
                <strong>Need assistance?</strong>
                <p>
                  If you are having any issues or have questions about how to
                  take the assessments, please send our team an email at{" "}
                  <u>info@amosinstitute.com</u>.
                </p>
              </div>
            </article>
          </>
        ) : (
          <article
            className="message is-danger"
            style={{ marginBottom: "100px" }}
          >
            <div className="message-body">
              <strong>{this.state.error.message}</strong>
              <p>
                We apologize for the in inconvenience. Please refresh the page
                and try again. If this issue persists, please send us an email
                at <u>info@amosinstitute.com</u>.
              </p>
            </div>
          </article>
        )}
      </>
    );
  }
}
